import * as React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import parse from 'html-react-parser'
import { generateUniqueButtonParams } from '/src/functions/paramTools'
import { calculateCardColumnWidths } from '/src/functions/columns/calculateColumnWidths'
import purpleIcon from '/src/dummy-data/images/purpleIcon'
import './styles.scss'

const ButtonLink = loadable(() => import('/src/components/Basic/Buttons/ButtonLink'))
const Card = loadable(() => import('/src/components/Cards/Card'))
const ContentDisclaimer = loadable(() => import('/src/components/Basic/ContentDisclaimer'))
const CtaContainer = loadable(() => import('/src/components/Basic/CtaContainer'))
const Image = loadable(() => import('/src/components/Media/Image'))
const Label = loadable(() => import('/src/components/Basic/Label'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))

const SliderCardList = ({
    type,
    backgroundColour,
    titleArea,
    columns,
    cardOrientation,
    addAlternativeColours,
    addImages,
    imageSize,
    addEyebrows,
    addTitles,
    largeTitles,
    addSubtitles,
    addDescriptions,
    addCtas,
    ctaType,
    itemsRepeater,
    addCta,
    callToAction,
    addSmallprint,
    smallprint,
    utm
}) => {
    const columnWidth = calculateCardColumnWidths(columns)

    const cardContent = (node, colourWay, buttonUtms) => {
        return (
            <Card
                variant={addAlternativeColours && colourWay === 'feature' ? 'feature' : undefined}
                className={'c-card-list__item-inner'}
            >
                {node.addLabel && (
                    <Label
                        variant={'outline'}
                        colour={node.labelColour}
                        content={node.labelText}
                        className={'c-card-list__item-label'}
                    />
                )}

                {addImages && node.image && (
                    <div className="c-card-list__item-image-container">
                        <Image
                            data={node.image}
                            className={`c-card-list__item-image c-card-list__item-image--${imageSize}`}
                        />
                    </div>
                )}

                <div className="c-card-list__item-content">
                    {addEyebrows && node.eyebrow && <div className="c-card-list__item-eyebrow">{node.eyebrow}</div>}

                    {addTitles && node.title && (
                        <h3
                            className={`c-card-list__item-title ${
                                largeTitles ? 'c-card-list__item-title--large' : undefined
                            }`}
                        >
                            {node.title}
                        </h3>
                    )}

                    {addSubtitles && node.subtitle && <div className="c-card-list__item-subtitle">{node.subtitle}</div>}

                    {addDescriptions && node.description && (
                        <div className="c-card-list__item-description">{parse(node.description)}</div>
                    )}

                    {addCtas && node.callToAction && ctaType !== 'card' && (
                        <CtaContainer align={'centered'} variant={'reduced'}>
                            <ButtonLink
                                data={node.callToAction}
                                variant={
                                    addAlternativeColours && colourWay === 'feature'
                                        ? 'inverse'
                                        : addAlternativeColours && colourWay
                                        ? colourWay
                                        : 'primary'
                                }
                                size={columns === '4' ? 'sm' : undefined}
                                utm={buttonUtms}
                            />
                        </CtaContainer>
                    )}
                </div>
            </Card>
        )
    }

    return (
        <Section
            variant={backgroundColour}
            titleArea={titleArea}
            className={`c-card-list c-card-list--${cardOrientation}`}
        >
            {itemsRepeater && (
                <div className="row c-card-list__content-row">
                    {itemsRepeater.map((node, index) => {
                        const colourWay = node.alternativeColourWay

                        const ctaUtms = node?.utms || []
                        const buttonUtms = generateUniqueButtonParams(utm, ctaUtms)

                        if (type === 'standard' || (type === 'search' && node.count >= 1)) {
                            // If a standard card list, or if used for search, where we only want to show a category if it has more than 1 post within it
                            return (
                                <div
                                    key={index}
                                    className={`${columnWidth} c-card-list__item ${
                                        addAlternativeColours && colourWay ? `c-card-list__item--${colourWay}` : ''
                                    }`}
                                >
                                    {ctaType !== 'card'
                                        ? cardContent(node, colourWay, buttonUtms)
                                        : ctaType === 'card' &&
                                          node.callToAction && (
                                              <a className={'c-card-list__item-card-link'} href={node.callToAction.url}>
                                                  {cardContent(node, colourWay, buttonUtms)}
                                              </a>
                                          )}
                                </div>
                            )
                        } else return null
                    })}
                </div>
            )}

            {addCta && callToAction && (
                <CtaContainer align={'centered'}>
                    <ButtonLink data={callToAction} size={'lg'} icon={'arrow'} utm={utm} />
                </CtaContainer>
            )}

            {addSmallprint && smallprint && <ContentDisclaimer content={smallprint} />}
        </Section>
    )
}

SliderCardList.propTypes = {
    type: PropTypes.oneOf(['standard', 'search']),
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']).isRequired,
    titleArea: PropTypes.shape({
        addTitleArea: PropTypes.bool,
        title: PropTypes.string,
        description: PropTypes.string
    }),
    columns: PropTypes.oneOf(['3', '4']),
    cardOrientation: PropTypes.oneOf(['vertical', 'horizontal']),
    addAlternativeColours: PropTypes.bool,
    addImages: PropTypes.bool,
    imageSize: PropTypes.oneOf(['small', 'large', 'wide']),
    addEyebrows: PropTypes.bool,
    addTitles: PropTypes.bool,
    largeTitles: PropTypes.bool,
    addSubtitles: PropTypes.bool,
    addDescriptions: PropTypes.bool,
    addCtas: PropTypes.bool,
    ctaType: PropTypes.oneOf(['button', 'card']),
    itemsRepeater: PropTypes.arrayOf(
        PropTypes.shape({
            alternativeColourWay: PropTypes.oneOf(['alt1', 'alt2', 'alt3', 'alt4', 'feature']),
            addLabel: PropTypes.bool,
            labelText: PropTypes.string,
            labelColour: PropTypes.string,
            image: PropTypes.object,
            eyebrow: PropTypes.string,
            title: PropTypes.string,
            subtitle: PropTypes.string,
            description: PropTypes.string,
            callToAction: PropTypes.shape({
                url: PropTypes.string.isRequired,
                title: PropTypes.string,
                target: PropTypes.string
            }),
            utms: PropTypes.shape({
                addUtms: PropTypes.bool,
                utmsList: PropTypes.arrayOf(
                    PropTypes.shape({
                        key: PropTypes.string,
                        value: PropTypes.string
                    })
                )
            })
        })
    ).isRequired,
    addCta: PropTypes.bool,
    callToAction: PropTypes.shape({
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        target: PropTypes.string
    }),
    addSmallprint: PropTypes.bool,
    smallprint: PropTypes.string,
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string
}

SliderCardList.defaultProps = {
    type: 'standard',
    backgroundColour: 'light',
    titleArea: {
        addTitleArea: true,
        titlePosition: 'centered',
        title: 'Section Title'
    },
    columns: '3',
    cardOrientation: 'vertical',
    addAlternativeColours: false,
    addImages: true,
    imageSize: 'small',
    addEyebrows: false,
    addTitles: true,
    largeTitles: false,
    addSubtitles: false,
    addDescriptions: true,
    addCtas: true,
    ctaType: 'button',
    itemsRepeater: [
        {
            alternativeColourWay: 'alt1',
            addLabel: false,
            image: purpleIcon,
            title: 'Item title',
            subtitle: 'Item subtitle',
            description: 'Item description',
            callToAction: {
                title: 'Button title',
                url: '#'
            }
        },
        {
            alternativeColourWay: 'alt2',
            addLabel: false,
            image: purpleIcon,
            title: 'Item title',
            subtitle: 'Item subtitle',
            description: 'Item description',
            callToAction: {
                title: 'Button title',
                url: '#'
            }
        },
        {
            alternativeColourWay: 'alt3',
            addLabel: false,
            image: purpleIcon,
            title: 'Item title',
            subtitle: 'Item subtitle',
            description: 'Item description',
            callToAction: {
                title: 'Button title',
                url: '#'
            }
        }
    ],
    addCta: false,
    callToAction: {
        url: '#',
        title: 'Button title'
    },
    addSmallprint: false
}

export default SliderCardList

export const query = graphql`
    fragment SliderCardListForPageBuilder on WpPage_Acfpagebuilder_Layouts_SliderCardList {
        fieldGroupName
        backgroundColour
        addTitleArea
        title
        description
        columns
        addAlternativeColours
        addImages
        imageSize
        addEyebrows
        addTitles
        largeTitles
        addSubtitles
        addDescriptions
        addCtas
        ctaType
        itemsRepeater {
            alternativeColourWay
            addLabel
            labelText
            labelColour
            image {
                id
                localFile {
                    childImageSharp {
                        gatsbyImageData(width: 410)
                    }
                }
                altText
                title
            }
            eyebrow
            title
            subtitle
            descriptionExcerpt
            description
            downloadsRepeater {
                callToAction {
                    target
                    title
                    url
                }
                utms {
                    addUtms
                    utmsList {
                        key
                        value
                    }
                }
            }
        }
        addSmallprint
        smallprint
    }
`
