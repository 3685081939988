export const calculateCardColumnWidths = (numberOfColumns) => {
    const columnSetups = {
        twelveColumns: 'col-sm-6 col-sm-4 col-lg-1',
        sixColumns: 'col-sm-3 col-sm-2',
        fourColumns: 'col-sm-6 col-lg-3',
        threeColumns: 'col-sm-6 col-md-4',
        twoColumns: 'col-6',
        oneColumn: 'col-12'
    }

    const columnWidth =
        numberOfColumns === '12'
            ? columnSetups.twelveColumns
            : numberOfColumns === '6'
            ? columnSetups.sixColumns
            : numberOfColumns === '4'
            ? columnSetups.fourColumns
            : numberOfColumns === '3'
            ? columnSetups.threeColumns
            : numberOfColumns === '2'
            ? columnSetups.twoColumns
            : columnSetups.oneColumn

    return columnWidth
}

export const calculateImageColumnWidths = (numberOfColumns) => {
    const columnSetups = {
        twelveColumns: 'col-sm-6 col-sm-4 col-lg-1',
        sixColumns: 'col-3 col-sm-2',
        fourColumns: 'col-6 col-lg-3',
        threeColumns: 'col-6 col-md-4',
        twoColumns: 'col-6',
        oneColumn: 'col-12'
    }

    const columnWidth =
        numberOfColumns === '12'
            ? columnSetups.twelveColumns
            : numberOfColumns === '6'
            ? columnSetups.sixColumns
            : numberOfColumns === '4'
            ? columnSetups.fourColumns
            : numberOfColumns === '3'
            ? columnSetups.threeColumns
            : numberOfColumns === '2'
            ? columnSetups.twoColumns
            : columnSetups.oneColumn

    return columnWidth
}
