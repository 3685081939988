const purpleIcon = {
    altText: 'Alt text',
    localFile: {
        childImageSharp: {
            gatsbyImageData: {
                layout: 'constrained',
                placeholder: {
                    fallback:
                        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADLElEQVQ4y3WUTUhVQRSA77Mfa9OitS5cRovKFhVBUTmDSBoo2h1/Ss0ZKIm0mZL+ZtQMC4qZMpOQKKIoKBCijVDkJtBVRLQIQYJoEURFQfie7/ni3Dn3djV6cDj3zjvnu+dvTqCZLTHMrQDRoVtpmAtMaAMdunHDXNEwt2iYa43OmSvRYIf2hrkMnkcS/dIHJoxgqzVzdzRzRc1s3qDWzLUZZpfaMxdo+PgyIDfMnTEhOLhBw9ysDi1ElvfR2YJh0XtRMzelQ3vaMNtumDtnmNsaQZnNJEAdRmmlJA1LzgteEnDReL8pKA+krlMRznuAXdChW0DnuHaLUeqhhecFHYKNLaZkJko5tBnQMTCLX01H5GHeydcRIYa5z4a5GcPcnA7ty4kLr4O+huHMtSP3AkElpGyzKQimZP+BGXb91uX28Q3nm66u6645u76n1qwf636yhhMZdO7ryQiqMvw/wEWExLAvl9rGNm8p3156pKr3AidymhP5iRM5y4mc4EQ2AlRQFXCqMknKSyIMbQH1zyvt4+Vtu49VCqp+CKqKy4UTCfJIULUKoDBDSQ1jgSZBmgMtI42NOzrKUo5ZQVVeUFVAnU3BHwrigbk4Qu11Huv3vmHboYAT+QIdfiOoyKkEvYjndwVVb/C5FoA/sXvxmOTgvb/5hjm4s6sMDQupSHI8eZcG0hRUvsL/HkMN32k/sAWTSvdi68369j3Ha1Kg+4KqWQRB+v2+EfJZyuYjAEcx5RwOcAGAQ62j1R17TzQkxafygCBR4ecEkUM+MvUc4Qto9w3u4ZZlNyO6DYMtI4dbdx3dhIZxvZphRGDeOEkiy3Ei8/g8hdvCXkNoFq8YPN97O/YVovi0bFQGxN80c6izgkjQfQCLxDA3GaeOM/jrwanJtV1VJw/j2ICzT41EkDxGnuU0gs0JKtf5TeEvOCzXh7BFUpf/dl/DMER5PZlFH1XOA2Uc9XdBVWU02PEmjhesZrZRh3Y6bg7syp46A9BOQdUH3yDfaUHVvKDqKSeyApu0IqohQjPxGkLZaJir16Gt7q0bKEGHUkHVNkFlEydyv6CqIm6SIKoElsMf0lP9ByQTZG0AAAAASUVORK5CYII='
                },
                backgroundColor: 'transparent',
                images: {
                    fallback: {
                        src: '/static/f75a2aabddf15362d6a669d17629bedc/b26df/shield.png',
                        srcSet: '/static/f75a2aabddf15362d6a669d17629bedc/b26df/shield.png 512w',
                        sizes: '(min-width: 512px) 512px, 100vw'
                    },
                    sources: [
                        {
                            srcSet: '/static/f75a2aabddf15362d6a669d17629bedc/e281d/shield.avif 512w',
                            type: 'image/avif',
                            sizes: '(min-width: 512px) 512px, 100vw'
                        },
                        {
                            srcSet: '/static/f75a2aabddf15362d6a669d17629bedc/4d97f/shield.webp 512w',
                            type: 'image/webp',
                            sizes: '(min-width: 512px) 512px, 100vw'
                        }
                    ]
                },
                width: 300,
                height: 300
            }
        }
    }
}

export default purpleIcon
